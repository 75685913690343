import React from "react";
import "./contest.scss";

const Contest = () => {
  return (
    <div className="container-fluid contest-page">
      <div className="row">
        <h2>Contest</h2>
      </div>
    </div>
  );
};
export default Contest;
