import React from "react";
import "./news.scss";

const News = () => {
  return (
    <div className="container-fluid news-page">
      <div className="row">
        <h2>News</h2>
      </div>
    </div>
  );
};
export default News;
